html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  /* font-size: calc(8px + 0.390625vw); */
  background-color: var(--primary-color);
  /* overflow: auto;
  overscroll-behavior: none;
  height: 100vh;
  -webkit-overflow-scrolling: touch; */
}
/* *,
body {
  cursor: url(https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/cursorsthethscodep.png?x=6)
      20 0,
    auto !important;
} */


a {
  color: inherit;
  text-decoration: none;
}

html {
  /* scroll-behavior: smooth; */
}

#pauseOverlay {
  height: 100%;
  width: 100%;
}

.w-100 {
  width: 100%;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.d-none {
  display: none;
}

.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a2347;
}

.d-flex {
  display: flex;
}
.d-block {
  display: block !important;
}

.text-align-center {
  text-align: center;
}

.mobile {
  display: none;
}

.desktop {
  display: flex;
}

.overlay > * {
  width: 60px;
  height: 60px;
  margin: 0 20px;
}

*[slot="centered-chrome"] {
  margin: 0 15%;
  --media-control-hover-background: none;
  --media-control-background: none;
}

.widget-svg {
  stroke: #fff;
  stroke-width: 1.5;
}

.widget-svg:hover {
  stroke: none;
}

.form-control {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  height: auto;
  width: 100%;
  color: #fff;
  background: transparent;
}

.form-control:focus {
  outline: none;
}

.form-control.PhoneInput > input {
  outline: none;
  background: transparent;
  border: none;
  color: #fff;
}

/* custom checkbox  start*/
.custom-checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}
input:disabled {
  background-color: #ccc;
  color: #808080;
  cursor: not-allowed;
}
.custom-checkbox > input[type="checkbox"] {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #c2cedb;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: transparent;
  cursor: pointer;
}

.custom-checkbox > input[type="checkbox"]:checked {
  border: 1px solid #eb4547;
  background-color: #eb4547;
}

.custom-checkbox > input[type="checkbox"]:checked::before {
  content: "\2713";
  /*  content: "✔"; */
  display: block;
  color: #fff;
  position: absolute;
  font-size: 1rem;
  font-weight: 700;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-checkboxRedColor > input[type="checkbox"]:checked::before {
  color: #eb4547;
}

.cursor-pointer {
  cursor: pointer;
}

/* custom checkbox end */

/* custom radio button start */
.custom-radiobtn {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radiobtn > input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-radiobtn-checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-radiobtn:hover > input[type="radio"] ~ .custom-radiobtn-checked {
  background-color: #ccc;
}

/* When the radio button is checked, add a background */
.custom-radiobtn
  > input[type="radio"]:checked[type="radio"]
  ~ .custom-radiobtn-checked {
  background-color: #eb4547;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radiobtn-checked:after {
  content: "";
  position: absolute;
  display: none;
}

.videoTag {
  background-color: #eb4547;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  padding: 0.5rem;
  font-size: 0.7rem;
  color: #fff;
  border-bottom-left-radius: 1rem;
  opacity: 0;
  box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.2),
    -8px 8px 8px 0 rgba(0, 0, 0, 0.2);
  /* display: none; */
}

/* Show the indicator (dot/circle) when checked */
.custom-radiobtn
  > input[type="radio"]:checked[type="radio"]
  ~ .custom-radiobtn-checked:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radiobtn .custom-radiobtn-checked:after {
  top: 0.35rem;
  left: 0.35rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #fff;
}

/* custom radio button end */

.btn {
  margin-top: 2.2rem;
  border-radius: 7px;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem;
  background-color: #eb4547;
  color: #fff;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: center;
}

.btn:hover:not([disabled]) {
  background-color: #fff;
  color: #eb4547;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

button:disabled {
  background-color: #808080;
  cursor: not-allowed;
}

/* hide updown arrow of type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #0a2040 inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
input {
  -webkit-background-clip: text !important;
}

.modalOverlay,
.modalOverlayWithTint {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.modalOverlayWithTint {
  background: rgba(0, 0, 0, 0.4);
}

.modalOverlayWithBlur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(27, 50, 87, 0.6);
  backdrop-filter: blur(32px);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.liveText {
  font-size: 1rem;
}

.liveDot {
  display: inline-block;
  height: 0.5rem;
  border-radius: 50%;
  width: 0.5rem;
  background-color: #eb4547;
}

/* toast container class */
.Toastify__toast-theme--light {
  background-color: #eb4547;
  color: #fff;
}

.Toastify__toast-icon > svg {
  --toastify-icon-color-success: #fff;
}

.Toastify__close-button {
  color: #fff;
  opacity: 0.7;
}

/* interaction in webinar */
.clickedReaction {
  background-color: #fff;
  color: #eb4547 !important;
}

/* error */
.error {
  color: #eb4547;
  padding-bottom: 1rem;
}

.wzrk-overlay{
  pointer-events: none;
}

/* fixed invite modal in center */
.inviteModal {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* video loader start */
.videoLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
}

.videoLoader > img {
  max-height: 50px;
  max-width: 50px;
}

/* video loader end */

/* top 10 swiper UI start */

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  height: 100% !important;
  top: 0 !important;
  margin-top: 0 !important;
}
.swiper-button-prev {
  background-image: linear-gradient(to left, transparent, var(--primary-color));
  left: 0 !important;
}
.swiper-button-next {
  right: 0 !important;
  background-image: linear-gradient(
    to right,
    transparent,
    var(--primary-color)
  );
}

.swiper-pagination-bullet {
  width: 1rem !important;
  height: 1rem !important;
}
.swiper-pagination-bullet-active {
  background-color: #eb4547 !important;
}

/* top 10 swiper UI  end*/
button[data-state="subtitles"] {
  font-weight: bold;
  background: transparent !important;
  padding-left: 1rem;
  display: none;
}

.subtitles-menu {
  display: none;
  position: absolute;
  bottom: 6.5rem;
  background: #666;
  list-style-type: none;
  margin: 0;
  right: 1.5rem;
  padding: 1rem;
}

.subtitles-menu li {
  padding: 0;
  text-align: center;
  border-bottom: 1px solid #fff;
  border-radius: 2px;
}

.subtitles-menu li:last-child {
  border-bottom: none;
}

.subtitles-menu li button {
  border: none;
  /* background: #0a2347; */
  color: #fff;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem;
}

/* custom table start */
/* #pagination-first-page,  */
#pagination-last-page {
  display: none !important;
}

/* .subtitles-menu li button[data-state="active"] {
  background: #eb4547;
} */
::cue {
}

@media screen and (max-width: 1680px) {
  html,
  body {
    font-size: 80%;
  }
}

/* @media screen and (max-width: 1366px) {
  html,
  body {
    font-size: 80%;
  }
} */

@media only screen and (min-resolution: 200dpi) {
  html,
  body {
    font-size: 10px;
  }
}

@media screen and (max-width: 900px) {
    .form-control{
        font-size: 1.4rem;
    }
  media-control-bar.desktop {
    display: none;
  }
  media-control-bar.mobile {
    display: flex;
  }
}
@media screen and (max-width: 768px) {
    .form-control{
        font-size: 1.2rem;
    }
  [data-aos] {
    pointer-events: auto !important;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: none !important;
  }

  media-control-bar.desktop {
    /* display: none; */
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  *[slot="centered-chrome"] {
    margin: 0;
  }

  .overlay > * {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 600px) {
  html,
  body {
    font-size: 13px;
  }

  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }
  .form-control{
    font-size: 1.1rem;
}
}

@media screen and (max-width: 380px) {
  .form-control {
    padding: 1rem 1.5rem;
  }
  html,
  body {
    font-size: 11px;
  }
}

/* @media only screen and (min--moz-device-pixel-ratio: 1.25),
  (-o-min-device-pixel-ratio: 5/4),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-device-pixel-ratio: 1.25) {
  html,
  body {
    font-size: calc(8px + 0.390625vw);
  }
} */

/* @media only screen and (min--moz-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (-webkit-min-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5) {
  body,
  html {
    font-size: calc(10px + 0.390625vw);
  }
} */
