.container {
  font-weight: 400;
  z-index: 100;
  background: rgba(13, 24, 64, 0.8);
  backdrop-filter: blur(6.5px);
  border: 1px solid #eb4547;
  position: fixed;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  bottom: -0.3rem; //calc(env(safe-area-inset-bottom) - 1px);
  left: env(safe-area-inset-left);
  right: env(safe-area-inset-right);
  
  .box {
    font-size: 1rem;
    padding: 1rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    color: #fff;

    .title {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.1rem;

      img {
        max-height: 2.5rem;
      }
    }

    .desc {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 300;
    }

    .btnContainer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      .btn {
        color: #eb4547;
      }

      .close {
        font-size: 1.1rem;
        text-decoration: underline;
      }

      .closeIcon {
        height: 17px;
        width: 17px;
      }

      .getApp {
        font-weight: bold;
        background: #ffffff;
        border-radius: 35px;
        padding: .5rem 1rem;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .container {
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 380px) {
  .container {
    .box {
      font-size: .8rem;
    }
  }
}